import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['answer']

  connect() {
    this.element.addEventListener('click', e => {
      this.answerTarget.classList.toggle('hidden')
    })
  }

  disconnect() {}
}
