import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  connect11() {
    this.setTableHeight()
    window.addEventListener('resize', this.setTableHeight.bind(this))
  }
  setTableHeight() {
    const cardBody = this.element.querySelector('.card-body')
    const table = this.element.querySelector('.table-responsive')
    const scopes = this.element.querySelector('.scopes')
    let leftHeight = cardBody.clientHeight - scopes.clientHeight

    console.log("leftHeight is ", leftHeight, cardBody.clientHeight, scopes.clientHeight)
    table.style = `max-height: ${leftHeight}px; min-height: 100px`;
  }
  connect2() {
    const cardBody = this.element.querySelector('.card-body')
    const table = this.element.querySelector('.table-responsive')
    const scopes = this.element.querySelector('.scopes')
    const leftHeight = cardBody.clientHeight - scopes.clientHeight
    table.style = `max-height: ${leftHeight}px`;


    // table.style = `max-height: ${this.element.clientHeight}px; overflow-y:hidden`
    // console.log(this.element, this.element.clientHeight, this.element.childElementCount, cardBody, cardBody.scrollHeight)
  }
  static targets = ['header', 'body', "bodyWrapper"]
  connect3() {
    const bodyWidth = this.bodyTarget.clientWidth
    // this.bodyWrapperTarget.style = `width: ${bodyWidth}px`
    // console.log(this.bodyWrapperTarget.clientWidth, bodyWidth)
  }
}
