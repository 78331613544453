import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    openid: String,
    order: String
  }
  connect() {
    console.log('order', this.orderValue)
  }

  // const data = {}
  // $.ajax({
  //   url: '/api/v1/wechat/pay',
  //   type: 'post',
  //   dataType: 'json',
  //   data: {
  //     openid: "<%= session[:openid] %>",
  //     number: "<%= @order.number %>"
  //   },
  //   success: function(data) {
  //     console.log(data, 'data------')
  //     if (data.err_code) {
  //       alert(data.err_code_des)
  //     } else {
  //       onBridgeReady();

  //       function onBridgeReady() {
  //         window.WeixinJSBridge.invoke(
  //           'getBrandWCPayRequest', {
  //             appId: data.appId, //公众号名称，由商户传入
  //             timeStamp: data.timeStamp,
  //             nonceStr: data.nonceStr,
  //             package: data.package,
  //             signType: data.signType,
  //             paySign: data.paySign,
  //           },
  //           function(res) {
  //             if (res.err_msg == "get_brand_wcpay_request:ok") {
  //               // 表示已经支付,res.err_msg将在用户支付成功后返回 ok。
  //               window.location.href = "<%= my_order_path(@order) %>";
  //             }
  //           }
  //         );
  //       }
  //       if (typeof WeixinJSBridge == "undefined") {
  //         if (document.addEventListener) {
  //           document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
  //         } else if (document.attachEvent) {
  //           document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
  //           document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
  //         }
  //       } else {
  //         onBridgeReady();
  //       }
  //     }
  //   },
  //   error: function() {
  //     console.log('出错了')
  //   }
  // })

  submit() {
    const orderNumber = this.orderValue
    // Turbo.visit(`/my/orders/${orderNumber}`)
    fetch('/api/v1/wechat/pay', {
      method: 'POST',
      body: JSON.stringify({
        number: this.orderValue,
        openid: this.openidValue
      }),
      headers: {
        'content-type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.err_code) {
          alert(data.err_code_des)
        } else {
          // onBridgeReady()
          function onBridgeReady() {
            window.WeixinJSBridge.invoke(
              'getBrandWCPayRequest',
              {
                appId: data.appId, //公众号名称，由商户传入
                timeStamp: data.timeStamp,
                nonceStr: data.nonceStr,
                package: data.package,
                signType: data.signType,
                paySign: data.paySign
              },
              function (res) {
                if (res.err_msg == 'get_brand_wcpay_request:ok') {
                  // 表示已经支付,res.err_msg将在用户支付成功后返回 ok。
                  Turbo.visit(`/orders/${orderNumber}`)
                  // alert('支付成功')
                  // Turbo.visit(`/my/orders/${orderNumber}`, {
                  //   action: 'replace'
                  // })
                  // window.location.href = `/my/orders/${orderNumber}`
                }
              }
            )
          }

          if (typeof WeixinJSBridge == 'undefined') {
            if (document.addEventListener) {
              document.addEventListener(
                'WeixinJSBridgeReady',
                onBridgeReady,
                false
              )
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
            }
          } else {
            onBridgeReady()
          }
        }
      })
  }
}
