import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['list', 'overlay']
  connect() {}

  toggle() {
    this.listTarget.classList.toggle('exam-pages__show')
    this.overlayTarget.classList.toggle('exam-pages__overlay-show')
  }
}
