import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    right: Array,
    current: Number,
    url: String
  }

  connect() {
    console.log('Page info, Stimulus!')
  }

  toggle() {
    console.log('page info toggle')
    let ul = document.getElementById('list')
    ul.innerHTML = ''
    this.render()
  }

  render() {
    console.log('render')
    this.test()

    // this.goto(this.currentValue)

    // window.requestAnimationFrame(() => {
    //   const el = document.getElementsByClassName('exam-pages__show')[0]
    //   const child = document.querySelector("[data-id~='1000']")
    //   console.log(child.offsetTop - el.offsetTop)
    //   el.scrollTop = child.offsetTop - el.offsetTop
    // })
  }

  goto(id) {
    setTimeout(() => {
      const el = document.getElementsByClassName('exam-pages__show')[0]
      const child = document.querySelector(`[data-id~='${id}']`)
      console.log(child.offsetTop - el.offsetTop)
      el.scrollTop = child.offsetTop - el.offsetTop - el.offsetHeight / 2
    }, 1000)
  }

  pages() {
    let ul = document.getElementById('list') //获取容器

    this.rightValue.map((item, index) => {
      let li = document.createElement('li')
      li.innerHTML = `<a href="${this.urlValue}?page=${index + 1}">${
        index + 1
      }</a>`
      ul.appendChild(li)
    })
  }

  test() {
    const url = this.urlValue
    let now = Date.now() // 记录任务开始时间
    let ul = document.getElementById('list') //获取容器
    let total = this.rightValue.length
    let pageSize = 50
    let currentPage = 1
    let endPage = Math.ceil(total / pageSize)
    let currentTotal = total - pageSize * (currentPage - 1)
    let getClasses = index => {
      let classes = 'bg-gray-100'
      if (index === this.currentValue) {
        classes += ' border border-blue-400'
      }
      classes += ` ${this.rightValue[index - 1] ? 'exam-right' : 'exam-wrong'}`
      return classes
    }
    function load() {
      if (currentPage > endPage) {
        return false
      }
      let currentCount = Math.min(currentTotal, pageSize)
      // console.log(currentCount, currentTotal, currentPage, 'currentCount')
      window.requestAnimationFrame(() => {
        for (let i = 1; i <= currentCount; i++) {
          let num = pageSize * (currentPage - 1) + i
          let classes = getClasses(num)
          let li = document.createElement('li')
          li.dataset['id'] = num
          li.innerHTML = `<a class="${classes}" href="${url}?page=${num}">${num}</a>`
          ul.appendChild(li)
        }
        currentPage++
        currentTotal = total - pageSize * (currentPage - 1)
        load()
      })
      console.log(Date.now() - now, 'time') // 160
    }
    load()
  }
}
