import SoftKey3A from '../libs/UsbKey3A/SoftKey3A'

import { Controller } from '@hotwired/stimulus'

var digitArray = new Array('0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f');

function toHex( n ) {

    var result = ''
    var start = true;

    for ( var i=32; i>0; ) {
        i -= 4;
        var digit = ( n >> i ) & 0xf;

        if (!start || digit != 0) {
            start = false;
            result += digitArray[digit];
        }
    }

    return ( result == '' ? '0' : result );
}

export default class extends Controller {
  static targets = [ "pubkey", "prikey", "username", "password", "pin" ]
  async connect() {
  }

  setInput(ret) {
    this.pubkeyTarget.value = ret.GenPubKeyX + ret.GenPubKeyY
    this.prikeyTarget.value = ret.GenPriKey
  }

  checkInputs() {
    if (this.usernameTarget.value == "") {
      window.alert ("请输入用户名");return false;
    }

    if (this.passwordTarget.value == "") {
      window.alert ("请输入加密锁密码");return false;
    }
    return true
  }

  async check() {
    if (!this.checkInputs()) {
      return false
    }
    var ret, version, versionex, DevicePath

    var mSoftKey3A=new SoftKey3A();//注意，由于USB的操作是异步的，所以所有函数的调用前必须加await !!!!!!!!!

    DevicePath = await mSoftKey3A.FindPort(1);//'查找是否存在多把加密锁

    if( mSoftKey3A.GetLastError()== 0 )
    {
      window.alert ("系统上发现有2把及以上的加密锁，请只插入要进行的加密锁。");return false;
    }
    DevicePath =await mSoftKey3A.FindPort(0);//'查找是否存在加密锁
    if( mSoftKey3A.GetLastError()!= 0 )
    {
      window.alert ( "未发现加密锁，请插入加密锁");return false;
    }

    {
      const oldPin = this.pinTarget.value
      const pin = this.passwordTarget.value
      ret = await mSoftKey3A.YtSetPin(oldPin, pin, DevicePath);
      console.log(ret, "ret", pin);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("设置加密锁密码错误");return false;
      }
      console.log(ret)
    }

    {
      ret = await mSoftKey3A.StarGenKeyPair(DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("生成密钥错误");return false;
      }
      console.log(ret, ret.GenPubKeyX.length, ret.GenPubKeyY.length)
      this.setInput(ret)
    }



    {
      const username = this.usernameTarget.value
      ret = await mSoftKey3A.Set_SM2_KeyPair(ret.GenPriKey, ret.GenPubKeyX, ret.GenPubKeyY,username, DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("设置密钥错误");return false;
      }
      console.log(ret)
    }

    {
      ret = await mSoftKey3A.Get_SM2_PubKey(DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("获取密钥错误");return false;
      }
      console.log(ret)
    }
  }
  async reset() {
    var ret, version, versionex, DevicePath

    var mSoftKey3A=new SoftKey3A();//注意，由于USB的操作是异步的，所以所有函数的调用前必须加await !!!!!!!!!

    DevicePath = await mSoftKey3A.FindPort(1);//'查找是否存在多把加密锁

    if( mSoftKey3A.GetLastError()== 0 )
    {
      window.alert ("系统上发现有2把及以上的加密锁，请只插入要进行的加密锁。");return false;
    }
    DevicePath =await mSoftKey3A.FindPort(0);//'查找是否存在加密锁
    if( mSoftKey3A.GetLastError()!= 0 )
    {
      window.alert ( "未发现加密锁，请插入加密锁");return false;
    }

    {
      ret = await mSoftKey3A.ReSet(DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("初始化失败");return false;
      }
      console.log("初始化",ret)
    }

    {
      ret = await mSoftKey3A.SetHidOnly(true, DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("SetHidOnly失败");return false;
      }
      console.log("ret is ", ret)
    }
    alert("初始化成功")
  }
}
