// import $ from 'cash-dom'

import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
  parseHTML
} from '../helpers'
import { circleProgress } from '../svg'
const previewTemplate = `<div class="preview-box">

  </div>`

const actionTeplate = `<div class="absolute top-0 left-0 flex items-center justify-around w-full h-full text-white opacity-0 cursor-pointer hover:opacity-100 hover:bg-opacity-50 hover:bg-black">
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 upload-view-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 upload-del-btn" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" data-action="click->upload#remove">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>
</div>`
export default class extends Controller {
  static targets = ['input', 'box', 'preview', 'del']
  static values = { maxFiles: Number }

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

  // Private
  hideFileInput() {
    this.inputTarget.style.display = 'none'
  }

  // preview
  previewFile(file) {
    var img = document.createElement('img')
    if (file.type == 'image/png' || file.type == 'image/jpeg') {
      var reader = new FileReader()
      // Set the image once loaded into file reader
      reader.onload = function (e) {
        console.log(e.target.result)
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    } else {
      img.src = 'https://hxbms-dev.oss-cn-shanghai.aliyuncs.com/file.png'
    }

    let template = parseHTML(previewTemplate)
    let actions = parseHTML(actionTeplate)
    template.appendChild(img)

    template.appendChild(actions)

    console.log(template, 'template')
    if (
      this.maxFilesValue < 2 &&
      this.previewTarget.querySelector('.preview-box')
    ) {
      this.previewTarget.querySelector('.preview-box').remove()
    }
    this.boxTarget.insertAdjacentElement('beforebegin', template)

    // if (this.maxFilesValue > 1) {
    //   this.boxTarget.insertAdjacentElement('beforebegin', template)
    // } else {
    //   if (this.previewTarget.querySelector('.preview-box')) {
    //     this.previewTarget.querySelector('.preview-box').remove()
    //   }
    //   this.boxTarget.insertAdjacentElement('beforebegin', template)
    // }
    return template
  }

  bindEvents() {
    this.boxTarget.addEventListener('click', e => {
      this.inputTarget.click()
    })

    this.inputTarget.addEventListener('change', e => {
      const files = Array.from(e.target.files)
      this.inputTarget.value = null
      console.log(files, 'files', this.isMultiple)
      const currentFilesNum =
        this.previewTarget.querySelectorAll('.preview-box').length
      if (
        files.length + currentFilesNum > this.maxFilesValue &&
        this.isMultiple
      ) {
        alert(`最多上传 ${this.maxFilesValue} 个文件`)
        console.log(
          files.length + currentFilesNum,
          files.length,
          currentFilesNum
        )
        return false
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        const previewBox = this.previewFile(file)

        setTimeout(() => {
          file && createDirectUploadController(this, file, previewBox).start()
        }, 500)
      }
    })
  }

  remove(e) {
    const previewBox = e.target.closest('.preview-box')
    previewBox.remove()
  }

  disconnect() {
    this.previewTarget.querySelector('.upload-box').remove()
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') }
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles')
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true
  }

  get form() {
    return this.element.closest('form')
  }

  get submitButton() {
    return findElement(this.form, 'input[type=submit], button[type=submit]')
  }

  get isMultiple() {
    return this.inputTarget.getAttribute('multiple') === 'multiple'
  }

  // doms

  get previewDom() {
    return this.previewTarget
  }

  get boxDom() {
    return this.boxTarget
  }
}
class DirectUploadController {
  constructor(source, file, previewBox) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
    this.previewBox = previewBox
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.source.form
      .querySelector("input[type='submit']")
      .setAttribute('disabled', true)
    this.directUpload.create((error, attributes) => {
      this.source.form
        .querySelector("input[type='submit']")
        .removeAttribute('disabled')
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.previewBox.setAttribute('id', attributes.signed_id)
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  // Private
  createHiddenInput() {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    this.previewBox.append(input)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener('progress', event =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = (event.loaded / event.total) * 100
    console.log(progress)
    let content = parseHTML(
      `<div class="upload-percent absolute top-0 left-0 w-full h-full bg-gray-50">
      ${circleProgress(`${parseInt(progress)}%`, progress * 2.96)}</div>`
    )

    if (this.previewBox.querySelector('.upload-percent')) {
      this.previewBox.appendChild(content)
    }

    if (progress < 100) {
      this.previewBox
        .querySelector('.upload-progress__circle')
        .setAttribute('style', `stroke-dasharray: ${progress * 2.96}, 296;`)
      this.previewBox.querySelector(
        '.upload-progress__text'
      ).innerHTML = `${parseInt(progress)}%`
    } else {
      if (this.previewBox.querySelector('.upload-percent'))
        this.previewBox.removeChild(
          this.previewBox.querySelector('.upload-percent')
        )
    }
  }

  emitDropzoneUploading() {
    console.log('uploading')
  }

  emitDropzoneError(error) {
    console.log('error')
  }

  emitDropzoneSuccess() {
    console.log('success')
  }
}

// Top level...
function createDirectUploadController(source, file, previewBox) {
  return new DirectUploadController(source, file, previewBox)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  const html = `
    <div data-upload-target="box" class="upload-box">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
      </svg>
    </div>
  `
  controller.previewTarget.appendChild(parseHTML(html))
  return parseHTML(html)
}
