import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Mandarin } from 'flatpickr/dist/l10n/zh.js'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    console.log('stimulus-flatpickr has been initialized!');
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Mandarin
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)
  }
}
