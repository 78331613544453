import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // if (document.referrer) {
    //   this.element.style.display = 'block'
    // }
  }
  back() {
    console.log(document.referrer, 'document.referrer    ')
    window.history.back()
  }
}
