import SoftKey3A from '../libs/UsbKey3A/SoftKey3A'

import { Controller } from '@hotwired/stimulus'

var digitArray = new Array('0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f');

function toHex( n ) {

    var result = ''
    var start = true;

    for ( var i=32; i>0; ) {
        i -= 4;
        var digit = ( n >> i ) & 0xf;

        if (!start || digit != 0) {
            start = false;
            result += digitArray[digit];
        }
    }

    return ( result == '' ? '0' : result );
}

export default class extends Controller {
  static targets = [ "keyid", "rnd", "form", "username", "pin", "signed" ]
  async connect() {
    console.log("ukey");
  }

  async login() {
    const loginForm = this.formTarget;
    const pinCode = this.pinTarget.value || '123';

    if (
      navigator.userAgent.indexOf("MSIE") > 0 &&
      !navigator.userAgent.indexOf("opera") > -1
    ) {
      alert("请使用谷歌或者火狐浏览器，不支持IE");
      return false
    }

    if (this.pinTarget.value === "") {
      window.alert("请输入加密锁密码")
      return false
    }

    var ret, DevicePath

    var mSoftKey3A=new SoftKey3A()

    // DevicePath = await mSoftKey3A.FindPort(1);//'查找是否存在多把加密锁

    // if( mSoftKey3A.GetLastError()== 0 )
    // {
    //   window.alert ( "系统上发现有2把及以上的加密锁，请只插入要进行的加密锁。");return false;
    // }
    DevicePath =await mSoftKey3A.FindPort(0);//'查找是否存在加密锁
    if( mSoftKey3A.GetLastError()!= 0 )
    {
      window.alert ( "未发现加密锁，请插入加密锁");return false;
    }

    {
      ret = await mSoftKey3A.GetChipID(DevicePath);
      console.log(ret, "GetChipID")
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("返回芯片唯一ID时出现错误");return false;
      }
      this.keyidTarget.value = ret
    }
    {
      ret = await mSoftKey3A.Get_SM2_PubKey(DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("读取加密锁出错");return false;
      }
      // console.log(ret)
      this.usernameTarget.value = ret.sm2UserName
    }

    {
      ret = await mSoftKey3A.YtSign(this.rndTarget.value, pinCode, DevicePath);
      if (mSoftKey3A.GetLastError() != 0) {
        window.alert ("加密锁密码错误");return false;
      }
      // console.log(ret)
      this.signedTarget.value = ret
    }

    loginForm.requestSubmit();
  }
}
