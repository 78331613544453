import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Mandarin } from 'flatpickr/dist/l10n/zh.js'
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static values = { dateFormat: {type: String, default: "Y年m月"}, input: {type: String, default: ""} }

  initialize() {
    const dateFormat = this.dateFormatValue
    // console.log('stimulus-flatpickr has been initialized!', this.element, dateFormat);
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Mandarin,
      plugins: [new monthSelectPlugin({
        dateFormat
      })]
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates)
    console.log('but returns it also as a string', dateStr)
    console.log('and the flatpickr instance', instance)

    if (this.inputValue) {
      const input = document.querySelector(this.inputValue)
      // console.log(input, "input", this.inputValue)
      input.value = dateStr
    }
  }
}
