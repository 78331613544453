import { Turbo } from '@hotwired/turbo-rails'

import { Controller } from '@hotwired/stimulus'
import { slideToggle } from 'simple-slide-toggle'
export default class extends Controller {
  static targets = ['item']

  connect() {
    this.itemTargets.forEach(item => {
      const actives = item.getElementsByClassName('active')

      const btn = item.querySelector('.nav__link-dropdown')

      if (actives.length > 0) {
        btn.classList.add('menu-open')
        console.log('actives.length = ' + actives.length)
        item.querySelector('.nav-tree').style.display = 'block'
        item.querySelector('.arrow-icon').style.transform = 'rotate(180deg)'
      } else {
        item.querySelector('.nav-tree').style.display = 'none'
      }
    })
  }

  async toggle(e) {
    e.preventDefault()
    const target = e.currentTarget
    const dropdown = target.parentNode.querySelector('ul')
    const arrow = target.querySelector('.arrow-icon')
    const display = window.getComputedStyle(dropdown).display
    if ((display === 'none', arrow.style.transform)) {
      arrow.style.transform = ''
    } else {
      arrow.style.transform = 'rotate(180deg)'
    }
    await slideToggle(dropdown, 200)
  }

  disconnect() {
    Turbo.clearCache()
  }
}
