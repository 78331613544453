import { Controller } from '@hotwired/stimulus'
import { fetchWithTimeout } from "../libs/utils"
import IMask from 'imask';

export default class extends Controller {
  static targets = ['form', 'photoBase64', 'frontPhotoBase64', 'backPhotoBase64']
  static values = {
    url: String,
    name: { type: String, default: "student" },
    dispatch: { type: Boolean, default: false },
  }

  // connect() {
  //   console.log(this.element, this.extrasValue)
  // }

  // 提交表单
  async submit() {
    console.log('submit', this.formTarget.requestSubmit())
  }

  // 变更科目下拉
  async changeSubject(e) {
    const name = e.currentTarget.selectedOptions[0].text
    if (name === "--请选择培训项目---") {
      return
    }
    const code = e.currentTarget.selectedOptions[0].dataset.code
    console.log('changeSubject', e.currentTarget)
    document.getElementById("subject-code").innerHTML = code

    this.genWorkInfo(name)
  }

  // 生成工作简历
  genWorkInfo(name) {

    let prefix_str = "本人从"
    let append_str = `至今一直从事${name}工作`
    // var work_info_subject_block = document.getElementById('work_info_subject_block');
    var element_prefix = document.getElementById('work_info_subject_prefix');
    var element_append = document.getElementById('work_info_subject_append');
    // work_info_subject_block.style.display = "flex"
    element_prefix.innerHTML = prefix_str
    element_append.innerHTML = append_str
  }


  async readCard() {
    console.log('readCard')
    try {
      const response = await fetchWithTimeout(this.urlValue, {
        timeout: 3000
      })
      const data = await response.json()
      // console.log(data)
      if (data?.code !== "1000") {
        alert("请检查设备是否连接正常，身份证摆放是否正常")
      } else {
        this.renderForm(data.data)
      }
    } catch (error) {
      console.error("读取失败,请检查设备是否连接正常", error)
      alert('读取失败,请检查设备是否连接正常')
    } finally {
      console.log("finally")
    }
  }

  setInputValue(name, value) {
    const input = this.element.querySelector(`[id="${this.nameValue}_${name}"]`)
    // console.log(input, value)
    if (!input) {
      // alert(name)
      return
    }
    input.value = value

    if (this.dispatchValue) {
      input.dispatchEvent(new Event('change'))
    }
  }

  setPhotoImage(name, target, data) {
    this.setInputValue(
      `${name}_base64`,
      'data:image/jpeg;base64,' + data[name]
    )
    this[`${target}Base64Target`].src = 'data:image/jpeg;base64,' + data[name]
  }

  renderForm(data) {
    const cols = ['name', 'id_number', 'address', 'sex', 'nation']
    const pattern = /(\d{4})(\d{2})(\d{2})/
    const form = this.element.querySelector('form')
    cols.map(col => {
      let value = data[col]
      // if (col === 'birthday') {
      //   value = value.replace(pattern, '$1-$2-$3')
      // }
      this.setInputValue(col, value)
    })
    // 设置图片
    this.setPhotoImage('photo', 'photo', data)
    this.setPhotoImage('front_photo', 'frontPhoto', data)
    this.setPhotoImage('back_photo', 'backPhoto', data)
  }

  changeIdNumber(e) {
    document.getElementById("review_document_number").value = e.currentTarget.value
  }
}
