import { Controller } from '@hotwired/stimulus'
// import QRCode from 'qrcode'
import { qrcanvas } from 'qrcanvas'

export default class extends Controller {
  static values = {
    order: String
  }
  static targets = ['canvas']

  connect() {
    // this.element.textContent = 'QRCODE'
    console.log('order', this.orderValue)
    this.load()
  }

  load() {
    fetch('/api/v1/wechat/scan_pay', {
      method: 'POST',
      body: JSON.stringify({ order_number: this.orderValue }),
      headers: {
        'content-type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.code_url) {
          const canvas = qrcanvas({
            data: res.code_url,
            size: 300,
            resize: true
          })
          this.element.prepend(canvas)
          // QRCode.toCanvas(this.element, res.code_url, function (error) {
          //   if (error) console.error(error)
          //   console.log('success!')
          // })
        } else {
          if (res.err_code_des) {
            alert(res.err_code_des)
          } else {
            alert('请求出错')
          }
        }
      })
  }
}
