import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['filter', 'other']
  static values = { url: String }

  connect() {
    this.filterTarget.addEventListener('change', ev => {
      if (this.selectedFilter) this.fetchItems()
      else this.clearItems()
    })

    if (this.selectedFilter) this.fetchItems()
  }

  async fetchItems() {
    const response = await get(this.urlValue, {
      query: { filter: this.selectedFilter },
      responseKind: 'json'
    })

    if (response.ok) this.setItems(await response.json)
    else console.log(response)
  }

  setItems(items) {
    this.clearItems()
    items.map((model, i) => {
      this.otherTarget.options[i] = new Option(model.text, model.value)
    })
  }

  clearItems() {
    this.otherTarget.innerHTML = ''
  }

  get selectedFilter() {
    return this.filterTarget.value
  }
}
