import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['option', 'submit']
  connect() {
    // console.log(this.checked.length, 'elment')
  }

  hideSubmit() {
    const submitBtn = this.submitTarget.querySelector('input[type=submit]')
    if (submitBtn) {
      console.log('this.submitTarget', submitBtn)
      submitBtn.classList.add('pointer-events-none')
    }
  }

  get checked() {
    return this.optionTargets.filter(option => option.checked)
  }

  submit(event) {
    if (this.checked.length < 1) {
      alert('请选择选项')
      event.preventDefault()
    }
  }

  // preventDefault(event) {
  //   if (this.checked.length < 1) {
  //     alert('请选择选项')
  //     event.preventDefault()
  //   }
  // }

  disconnect() {}
}
