import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open(e) {
    const index = parseInt(e.target.dataset['index'])

    const tr = this.trs[index]
    const sb = this.siblings(tr)
    sb.forEach(el => {
      el.classList.remove('active')
    })
    tr.setAttribute('class', 'active')
  }

  get trs() {
    const trs = this.element.querySelectorAll('tbody tr')
    return trs
  }
  siblings(el) {
    if (el.parentNode === null) return []

    return Array.prototype.filter.call(
      el.parentNode.children,
      function (child) {
        return child !== el
      }
    )
  }
}
